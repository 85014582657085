import React, { useReducer, useRef } from 'react';
import * as yup from 'yup';
import axios from 'axios';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { toast } from 'react-toastify';
import SEO from '../components/seo';
import Layout from '../components/Layouts/Layout';
import CurrencyFormat from 'react-currency-format';
import ErrorModal from '../components/Modals/ErrorModal';
import ContactForm from '../components/ContactForm';

const formateDate = date => {
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

const regSchema = yup.object().shape({
  donationType: yup.string().required('Donation Type is required'),
  donationAmount: yup.string().required('Donation Amount is required'),
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup
    .string()
    .email('Must be a valid email address')
    .required('Email is required'),
  phone: yup.string().required('Phone Number is required'),
  address1: yup.string().required('Address is required'),
  address2: yup.string(),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  zipCode: yup.string().required('Zip Code is required'),
  message: yup.string(),
});

const currency = 'USD';
const style = { layout: 'vertical' };

const initialState = {
  donationType: '',
  donationAmount: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipCode: '',
  message: '',
  submitting: false,
  succeeded: false,
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'updateField':
      return { ...state, [action.field]: action.value };
    case 'startSubmit':
      return { ...state, submitting: true, succeeded: false, errors: [] };
    case 'successSubmit':
      return { ...state, submitting: false, succeeded: true };
    case 'errorSubmit':
      return { ...state, submitting: false, error: action.error };
    case 'closeModal':
      return { ...state, error: null };
    case 'reset':
      return { ...initialState };
    default:
      return state;
  }
};

const Donate = () => {
  const [donationState, dispatch] = useReducer(reducer, initialState);
  const formRef = useRef(null);

  const handleChange = event => {
    const { name, value } = event.target;
    dispatch({ type: 'updateField', field: name, value });
  };

  const resetForm = () => {
    dispatch({ type: 'reset' });
  };

  const getStateFromRef = () => {
    const formData = new FormData(formRef.current);
    const formDataObject = {};
    formData.forEach((value, key) => {
      formDataObject[key] = value;
    });
    return formDataObject;
  };

  const validateForm = async () => {
    const formData = getStateFromRef();
    const isValid = await regSchema.isValid(formData);
    if (!isValid) {
      toast.error('All fields are mandatory unless specified as optional.', {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    }
    return isValid;
  };

  const createOrder = async () => {
    const { donationAmount, donationType } = getStateFromRef();
    try {
      const response = await axios.post(
        `${process.env.GATSBY_URL}/api/orders`,
        {
          donationAmount: donationAmount,
          donationType: `Donation Type: ${donationType}`,
        }
      );
      return response.data.id;
    } catch (error) {
      dispatch({ type: 'errorSubmit', error });
    }
  };

  const onApprove = async (data, actions) => {
    const formData = getStateFromRef();
    try {
      const res = await axios.post(
        `${process.env.GATSBY_URL}/api/orders/${data?.orderID}/capture`,
        {
          formData,
        }
      );

      // Clear state
      dispatch({ type: 'successSubmit' });
      resetForm();

      toast.success('Thank you for your donation!', {
        position: 'bottom-center',
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    } catch (error) {
      dispatch({ type: 'errorSubmit', error });
    }
  };

  const onCancel = (data, actions) => {
    toast.error("Couldn't complete payment process. Please try again.", {
      position: 'bottom-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  };

  return (
    <Layout>
      <SEO title='Donate' />
      <section className='relative bg-gray-700 text-white'>
        <div className='container mx-auto py-16 min-h-screen'>
          <div className='flex flex-wrap pt-16 justify-center'>
            {/* Left Side */}
            <div className='px-2'>
              <div className='flex flex-wrap justify-center'>
                <div className='relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300'>
                  <div className='flex-auto p-5'>
                    <div className='text-center text-gray-700'>
                      <h3 className='text-xl font-bold text-orange-500 uppercase'>
                        Donate by Credit / Debit Card
                      </h3>
                    </div>
                    {/* <form ref={formRef}>
                      <div className='md:flex'>
                        <div className='w-full md:w-6/12 p-2'>
                          <label
                            className='block  text-gray-700 text-xs font-bold mb-2'
                            htmlFor='donationType'
                          >
                            Donation Type
                          </label>
                          <select
                            id='donationType'
                            name='donationType'
                            className='p-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                            placeholder='Select Donation Type'
                            value={donationState.donationType}
                            onChange={handleChange}
                            required
                          >
                            <option value=''>Select Type</option>
                            <option value='Donation'>Donation</option>
                            <option value='Thal'>Thal</option>
                            <option value='Padharamni'>Padharamni</option>
                            <option value='Shibir'>Shibir</option>
                            <option value='Mahapuja'>Mahapuja</option>
                            <option value='Annakut Mahotsav'>
                              Annakut Mahotsav
                            </option>
                            <option value='Other'>Other</option>
                          </select>
                        </div>
                        <div className='w-full md:w-6/12 p-2'>
                          <label
                            className='block  text-gray-700 text-xs font-bold mb-2'
                            htmlFor='amount'
                          >
                            Donation Amount
                          </label>
                          <CurrencyFormat
                            thousandSeparator={true}
                            id='donationAmount'
                            name='donationAmount'
                            value={donationState.donationAmount}
                            onChange={handleChange}
                            placeholder='Donation Amount'
                            required
                            className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                          />
                        </div>
                      </div>
                      <div className='md:flex'>
                        <div className='w-full p-2'>
                          <label
                            className='block  text-gray-700 text-xs font-bold mb-2'
                            htmlFor='message'
                          >
                            Note (Optional)
                          </label>
                          <textarea
                            rows='1'
                            cols='30'
                            id='message'
                            name='message'
                            value={donationState.message}
                            onChange={handleChange}
                            className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full'
                            placeholder='Type a message...'
                          />
                        </div>
                      </div>

                      <hr className='my-6 border-gray-400' />
                      <div className='md:flex'>
                        <div className='w-full md:w-6/12 p-2'>
                          <label
                            className='block  text-gray-700 text-xs font-bold mb-2'
                            htmlFor='first-name'
                          >
                            First Name
                          </label>
                          <input
                            id='firstName'
                            name='firstName'
                            type='text'
                            value={donationState.firstName}
                            onChange={handleChange}
                            className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                            placeholder='First Name'
                            required
                          />
                        </div>
                        <div className='w-full md:w-6/12 p-2'>
                          <label
                            className='block  text-gray-700 text-xs font-bold mb-2'
                            htmlFor='last-name'
                          >
                            Last Name
                          </label>
                          <input
                            id='lastName'
                            name='lastName'
                            type='text'
                            value={donationState.lastName}
                            onChange={handleChange}
                            className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                            placeholder='Last Name'
                            required
                          />
                        </div>
                      </div>

                      <div className='md:flex'>
                        <div className='w-full md:w-6/12 p-2'>
                          <label
                            className='block  text-gray-700 text-xs font-bold mb-2'
                            htmlFor='email'
                          >
                            Email
                          </label>
                          <input
                            id='email'
                            name='email'
                            type='email'
                            value={donationState.email}
                            onChange={handleChange}
                            className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                            placeholder='Email'
                          />
                        </div>
                        <div className='w-full md:w-6/12 p-2'>
                          <label
                            className='block  text-gray-700 text-xs font-bold mb-2'
                            htmlFor='phone'
                          >
                            Phone Number
                          </label>
                          <CurrencyFormat
                            id='phone'
                            name='phone'
                            format='+1 (###) ###-####'
                            mask='_'
                            value={donationState.phone}
                            onChange={handleChange}
                            placeholder='Phone Number'
                            className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                            required
                          />
                        </div>
                      </div>

                      <div className='md:flex'>
                        <div className='w-full md:w-6/12 p-2'>
                          <label
                            className='block  text-gray-700 text-xs font-bold mb-2'
                            htmlFor='address1'
                          >
                            Address 1
                          </label>
                          <input
                            id='address1'
                            name='address1'
                            type='text'
                            value={donationState.address1}
                            onChange={handleChange}
                            className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                            placeholder='Address'
                            required
                          />
                        </div>
                        <div className='w-full md:w-6/12 p-2'>
                          <label
                            className='block  text-gray-700 text-xs font-bold mb-2'
                            htmlFor='address1'
                          >
                            Address 2 (optional)
                          </label>
                          <input
                            id='address2'
                            name='address2'
                            type='text'
                            value={donationState.address2}
                            onChange={handleChange}
                            className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                            placeholder='Address'
                          />
                        </div>
                      </div>

                      <div className='md:flex'>
                        <div className='w-full md:w-6/12 p-2'>
                          <label
                            className='block  text-gray-700 text-xs font-bold mb-2'
                            htmlFor='city'
                          >
                            City
                          </label>
                          <input
                            id='city'
                            name='city'
                            type='text'
                            value={donationState.city}
                            onChange={handleChange}
                            className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                            placeholder='City'
                            required
                          />
                        </div>
                        <div className='w-full md:w-6/12 p-2'>
                          <label
                            className='block  text-gray-700 text-xs font-bold mb-2'
                            htmlFor='state'
                          >
                            State
                          </label>
                          <select
                            id='state'
                            name='state'
                            className='p-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                            placeholder='State'
                            value={donationState.state}
                            onChange={handleChange}
                            required
                          >
                            <option value=''>Select State</option>
                            <option value='AL'>Alabama</option>
                            <option value='AK'>Alaska</option>
                            <option value='AZ'>Arizona</option>
                            <option value='AR'>Arkansas</option>
                            <option value='CA'>California</option>
                            <option value='CO'>Colorado</option>
                            <option value='CT'>Connecticut</option>
                            <option value='DE'>Delaware</option>
                            <option value='FL'>Florida</option>
                            <option value='GA'>Georgia</option>
                            <option value='HI'>Hawaii</option>
                            <option value='ID'>Idaho</option>
                            <option value='IL'>Illinois</option>
                            <option value='IN'>Indiana</option>
                            <option value='IA'>Iowa</option>
                            <option value='KS'>Kansas</option>
                            <option value='KY'>Kentucky</option>
                            <option value='LA'>Louisiana</option>
                            <option value='ME'>Maine</option>
                            <option value='MD'>Maryland</option>
                            <option value='MA'>Massachusetts</option>
                            <option value='MI'>Michigan</option>
                            <option value='MN'>Minnesota</option>
                            <option value='MS'>Mississippi</option>
                            <option value='MO'>Missouri</option>
                            <option value='MT'>Montana</option>
                            <option value='NE'>Nebraska</option>
                            <option value='NV'>Nevada</option>
                            <option value='NH'>New Hampshire</option>
                            <option value='NJ'>New Jersey</option>
                            <option value='NM'>New Mexico</option>
                            <option value='NY'>New York</option>
                            <option value='NC'>North Carolina</option>
                            <option value='ND'>North Dakota</option>
                            <option value='OH'>Ohio</option>
                            <option value='OK'>Oklahoma</option>
                            <option value='OR'>Oregon</option>
                            <option value='PA'>Pennsylvania</option>
                            <option value='RI'>Rhode Island</option>
                            <option value='SC'>South Carolina</option>
                            <option value='SD'>South Dakota</option>
                            <option value='TN'>Tennessee</option>
                            <option value='TX'>Texas</option>
                            <option value='UT'>Utah</option>
                            <option value='VT'>Vermont</option>
                            <option value='VA'>Virginia</option>
                            <option value='WA'>Washington</option>
                            <option value='WV'>West Virginia</option>
                            <option value='WI'>Wisconsin</option>
                            <option value='WY'>Wyoming</option>
                          </select>
                        </div>

                        <div className='w-full md:w-6/12 p-2'>
                          <label
                            className='block  text-gray-700 text-xs font-bold mb-2'
                            htmlFor='zipCode'
                          >
                            Zip Code
                          </label>
                          <input
                            type='number'
                            id='zipCode'
                            name='zipCode'
                            value={donationState.zipCode}
                            onChange={handleChange}
                            className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                            placeholder='Zip Code'
                            required
                          />
                        </div>
                      </div>

                      <hr className='my-6 border-gray-400' />

                      {donationState.error !== null && (
                        <ErrorModal dispatch={dispatch} />
                      )}

                      <div className='text-center mt-6'>
                        <PayPalScriptProvider
                          options={{
                            'client-id': process.env.GATSBY_CLIENT_ID,
                            components: 'buttons',
                            currency,
                            'disable-funding': 'paylater',
                          }}
                        >
                          <div
                            className={donationState.submitting && 'spinner'}
                          >
                            <PayPalButtons
                              style={style}
                              disabled={donationState.donationAmount === ''}
                              className={
                                donationState.donationAmount === '' &&
                                'pointer-events-none'
                              }
                              onClick={validateForm}
                              createOrder={createOrder}
                              onApprove={onApprove}
                              onCancel={onCancel}
                            />
                          </div>
                        </PayPalScriptProvider>
                      </div>
                    </form> */}
                    <form
                      action='https://www.paypal.com/donate'
                      method='post'
                      target='_top'
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '200px',
                        marginTop: '2rem',
                      }}
                    >
                      <input
                        type='hidden'
                        name='hosted_button_id'
                        value='PKM4LWAQT746U'
                      />
                      <input
                        style={{ height: '4rem' }}
                        type='image'
                        src='https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif'
                        border='0'
                        name='submit'
                        title='PayPal - The safer, easier way to pay online!'
                        alt='Donate with PayPal button'
                      />
                      <img
                        alt=''
                        border='0'
                        src='https://www.paypal.com/en_US/i/scr/pixel.gif'
                        width='1'
                        height='1'
                      />
                    </form>
                    <hr className='my-6 border-gray-400' />
                    <div className='flex-auto p-5'>
                      <div className='text-center p-0 text-gray-700'>
                        <h3 className='text-xl font-bold text-orange-500 uppercase'>
                          Donate by Check
                        </h3>
                        <h3 className='text-lg m-2 leading-relaxed'>
                          Make check payable to{' '}
                          <strong className='text-red-500'>YDS Chicago</strong>{' '}
                          and mail it to:
                        </h3>
                        <h5 className='text-xl'>
                          4074 S. Archer Avenue, Chicago, IL 60632
                        </h5>
                      </div>
                      <hr className='my-6 border-gray-400' />
                      <h3 className='text-xl font-bold text-orange-500 text-center uppercase'>
                        Contact Us
                      </h3>
                      <ContactForm />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Donate by check / contact us section */}
            {/* <div className='w-full md:w-4/12 mr-auto px-4'>
              <div className='flex flex-wrap justify-center'>
                <div className='relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300'>
                  <div className='flex-auto p-5'>
                    <div className='text-center p-0 text-gray-700'>
                      <h3 className='text-xl font-bold text-orange-500 uppercase'>
                        Donate by Check
                      </h3>
                      <h3 className='text-lg m-2 leading-relaxed'>
                        Make check payable to{' '}
                        <strong className='text-red-500'>YDS Chicago</strong>{' '}
                        and mail it to:
                      </h3>
                      <h5 className='text-xl'>
                        4074 S. Archer Avenue, Chicago, IL 60632
                      </h5>
                    </div>
                    <hr className='my-6 border-gray-400' />
                    <h3 className='text-xl font-bold text-orange-500 text-center uppercase'>
                      Contact Us
                    </h3>
                    <ContactForm />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Donate;
